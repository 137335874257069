import firebase from "firebase/app"
require("firebase/firestore")
require("firebase/auth")

export default {

    noImage(event){
        event.target.src = "https://firebasestorage.googleapis.com/v0/b/mtb-platform.appspot.com/o/logo%2Fdefault.png?alt=media&token=4b2a4994-bb36-4717-8672-ef72fab08782"
    },

    loadLogo(id){
        return `https://storage.googleapis.com/account_logos/original/${id}`
    },

    scrollTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      
    hideModal(ref){
        this.$refs[ref].hide() 
      },
    
    filterItembySearch(items, param) {
        return items.filter((item) => {
          return item[param].toLowerCase().includes(
            this.search.toLowerCase()
          )
        })
    },

    filterItemByStatus(items, status) {
      if (status){
        return items.filter((item)=>{
          return item.Opportunity.StageName == status
        })
      } else {return items}
    },

    filterItemByVertical(items, vertical) {
      return items.filter((item)=>{
        if (item.Industry_Verticals__c){
          return item.Industry_Verticals__c.includes(vertical)
        } else {return true}
      })
    },

    filterItemByCountry(items, country) {
      return items.filter((item)=>{
        if (item.HQ_Country__c){
          return item.HQ_Country__c.includes(country)
        } 
      })
    },  
    recordDownload(routeName){
      let currentUser = firebase.auth().currentUser
      firebase
      .firestore()
      .collection("users")
      .doc(currentUser.email)
      .update({
        downloaded: firebase.firestore.FieldValue.arrayUnion(routeName)
      })
      .then(()=>{
        console.log("DONE")
      })
      .catch((err) => {
        console.log(err.message)
      });

      var reportDownloadCount
      firebase
      .firestore()
      .collection("publications")
      .doc(routeName)
      .get().then((doc) => {
        if (doc.exists) {
          if (doc.data().downloads){
            reportDownloadCount = doc.data().downloads
          } else {
            reportDownloadCount = 0
          }
        } else {
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });

    console.log(reportDownloadCount)

      firebase
      .firestore()
      .collection("publications")
      .doc(routeName)
      .set({ downloaded: reportDownloadCount+1 })
      .then(()=>{
        console.log("DONE")
      })
      .catch((err) => {
        console.log(err.message)
      });
    },

    recordDownloadDigitalReport(routeName){
      let currentUser = firebase.auth().currentUser
      firebase
      .firestore()
      .collection("users")
      .doc(currentUser.email)
      .update({
        downloaded: firebase.firestore.FieldValue.arrayUnion(routeName)
      })
      .catch((err) => {
        console.log(err.message)
      });

      let downloadedReport = {
        id:routeName,
        timestamp: Date.now()
      }

      firebase
      .firestore()
      .collection("users")
      .doc(currentUser.email)
      .update({
        downloadedReports: firebase.firestore.FieldValue.arrayUnion(downloadedReport)
      })
      .catch((err) => {
        console.log(err.message)
      });
    },
}