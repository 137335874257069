<template>
  <b-container fluid>
    <b-row>
      <div class="footer">
        <b-row>
          <b-col>
            <a href="https://mindthebridge.com/" target="_blank">
              <img alt="Vue logo" class="mt-4" src="../assets/mtb_logo_white_small.png" height="95px" />
            </a>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <a href="https://www.facebook.com/mindthebridge/" target="_blank">
              <v-icon name="brands/facebook" class="icon m-3" scale="1.7" />
            </a>
            <a href="https://www.linkedin.com/school/mindthebridge/" target="_blank">
              <v-icon name="brands/linkedin-in" class="icon m-3" scale="1.7" />
            </a>
            <a href="https://www.instagram.com/mindthebridge/" target="_blank">
              <v-icon name="brands/instagram" class="icon m-3" scale="1.7" />
            </a>
            <a href="https://www.youtube.com/user/mindthebridgeTV/" target="_blank">
              <v-icon name="brands/youtube" class="icon m-3" scale="1.7" />
            </a>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <small
              >© 2007-{{ new Date().getFullYear() }} Mind the Bridge | All Rights Reserved | <b-link style="(color:white!important;)" href="https://www.iubenda.com/privacy-policy/18888635" target="_blank">Privacy Policy</b-link> |
              <b-link style="(color:white!important;)" href="https://www.iubenda.com/privacy-policy/18888635/cookie-policy" target="_blank">Cookie Policy</b-link>
            </small>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  background-color: #051930; 
  padding-top: 15px;
  margin-top: 200px;
  color: white;
  bottom: 0;
  width: 100vw;
  max-width: 100%;
  position: relative;
  height: 260px;
}

.icon {
  color: white;
}
</style>
