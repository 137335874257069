import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import firebase from 'firebase/app'
require("firebase/firestore")

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    items:[],
    reports:[],
    directories:[],
    userInfo:null,
    isAdmin:false,
    isMtb:false,
  },
  getters: {
    userInfo: state => state.userInfo,
  },
  mutations: {
    LOGOUT:(state)=>{
      state.items = []
      state.reports=[]
      state.directories=[]
      state.userInfo=null
      state.isAdmin=false
      state.isMtb=false
    },
    ITEMS_LOAD:(state, payload)=>{
      state.items = payload
    },

    REPORTS_LOAD: (state, payload) => {
      state.reports = payload;
    },
    DIRECTORIES_LOAD: (state, payload) => {
      state.directories = payload;
    },

    SET_USER_INFO: (state, payload) => {
      state.userInfo = payload;
    },
    SET_USER_CLAIM_ADMIN: (state, payload) => {
      state.isAdmin = payload;
    },
    SET_USER_CLAIM_MTB: (state, payload) => {
      state.isMtb = payload;
    },
    
  },
  actions: {

    async setUserInfo({commit, dispatch}){
      return new Promise(async(resolve,reject)=>{

        // HANDLE MTC CLAIM ASSIGNMENT AT FIRST GOOGLE LOGIN FOR MTB STAFF
        if (firebase.auth().currentUser.email.endsWith("mindthebridge.org") && firebase.auth().currentUser.metadata.creationTime === firebase.auth().currentUser.metadata.lastSignInTime && !(await firebase.auth().currentUser.getIdTokenResult()).claims.mtb) {
          await firebase.auth().currentUser.reload();
          await new Promise((resolve) => {
            return setTimeout(async () => {
              //AWAITS FOR THE EXECUTION OF THE CLAIMS TO HAPPEN
              await firebase.auth().currentUser.getIdTokenResult(true);
              return resolve();
            }, 3000)
          })
        }


        if ((await firebase.auth().currentUser.getIdTokenResult()).claims.mtb === true) {
          commit("SET_USER_INFO", {
            email: firebase.auth().currentUser.email,
            id: firebase.auth().currentUser.uid,
            type:"mtb",
            hasCompletedSignup:true,
            firstName: firebase.auth().currentUser.displayName ? firebase.auth().currentUser.displayName.split(" ")[0] : "MTB Employee",
            lastName: firebase.auth().currentUser.displayName ? firebase.auth().currentUser.displayName.split(" ")[1] : firebase.auth().currentUser.email,

          })
          dispatch("setUserClaims")
          .then(()=>{
            resolve()
          })
          .catch((error)=>{
            reject(error)

          })
        } else {
          firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.email)
          .get()
          .then((doc)=>{
            var userInfo = doc.data()
            commit("SET_USER_INFO", userInfo)
          })
          .then(()=>{resolve()})
          .catch((error)=>{reject(error)})
        }
      })
    },

    setUserClaims({commit}){
      return new Promise((resolve,reject)=>{
        firebase.auth().currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            if (idTokenResult.claims.admin) {
              commit("SET_USER_CLAIM_ADMIN", true)
              // console.log("I AM ADMIN")
            } else if (idTokenResult.claims.mtb) {
              commit("SET_USER_CLAIM_MTB", true)
              // console.log("I AM MTB")
            }
            resolve()
          },error => {
          reject(error)
        })
      })
    },

    loadReports({commit}) {
      let reportList = [];
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("publications")
          .orderBy("year", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function(doc) {
              var report = doc.data()
              // if (report.show){reportList.push(report)}
              reportList.push(report)
            });

            reportList.sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.date) - new Date(a.date);
            });

            commit("REPORTS_LOAD", reportList);
            resolve(),
              (error) => {
                reject(error);
              };
          });
      });
    },

    loadDirectories({commit}) {
      let directoryList = [];
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("directories")
          .orderBy("date", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function(doc) {
              var directory = doc.data()
              // if (report.show){reportList.push(report)}
              directoryList.push(directory)
            });
            commit("DIRECTORIES_LOAD", directoryList);
            resolve(),
              (error) => {
                reject(error);
              };
          });
      });
    },

    loadData({commit}, payload){
      // var t0 = performance.now()
      // console.log(payload)
      return new Promise((resolve,reject)=>{
        axios
          .get("https://us-central1-mtb-research.cloudfunctions.net/getAirtableData", {
            params:{
              baseId:payload.baseId,
              tableName:payload.tableName,
              view: payload.view,
            }
          }).then((res)=>{
            // var t1 = performance.now()
            // console.log("Call to load VUEX  took " + (t1 - t0)/1000 + " seconds.")
            let items 
            if (res.data[0]['Outpost Name']){
               items = res.data.slice().sort((a, b) => (a['Outpost Name'] > b['Outpost Name']) ? 1 : -1)
            }
            else {
              items = res.data
            }
            commit('ITEMS_LOAD',items)
            resolve()
          },error => {
            // console.log(error)
            reject(error)
          })
        })
      },

      loadIPO({commit}, payload){

        let payload1= payload[0]
        let payload2= payload[1]

        return new Promise(async (resolve,reject)=>{

          try{
            let items 
            let res1 = await axios
            .get("https://us-central1-mtb-research.cloudfunctions.net/getAirtableData", {
              params:{
                baseId:payload1.baseId,
                tableName:payload1.tableName,
                view: payload1.view,
              }
            })
  
            items = res1.data
  
            let res2 = await axios
            .get("https://us-central1-mtb-research.cloudfunctions.net/getAirtableData", {
              params:{
                baseId:payload2.baseId,
                tableName:payload2.tableName,
                view: payload2.view,
              }
            })
  
            items = items.concat(res2)
            items = items.slice().sort((a, b) => (a['IPO Funding'] > b['IPO Funding']) ? -1 : 1)
  
            commit('ITEMS_LOAD',items)
            resolve()

          }
          catch(error){
            reject(error)

          }
    
        })
      }
    }
  })
