import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from "../store/index.js"

import firebase from 'firebase/app'
require("firebase/auth");

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import '@/assets/custom.scss'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)

import VueApexCharts from 'vue-apexcharts' 
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import VueScreenSize from 'vue-screen-size'
Vue.use(VueScreenSize)

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import "leaflet/dist/leaflet.css";

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/reports' },

  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Account/Signup.vue'),
    meta: {
      navbar:false,
    }
  },
  {
    path: '/signup-email',
    name: 'SignupEmail',
    component: () => import('../views/Account/SignupEmail.vue'),
    meta: {
      navbar:false,
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('../views/Account/VerifyEmail.vue'),
    meta: {
      navbar:false,
    }
  },
  {
    path: '/complete-signup',
    name: 'CompleteSignup',
    component: () => import('../views/Account/CompleteSignup.vue'),
    meta: {
      requiresAuth: false, 
      navbar:false,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Account/Login.vue'),
    meta: {
      navbar:false,
    }
  },
  {
    path: '/login-mtb',
    name: 'LoginMTB',
    component: () => import('../views/Account/LoginMTB.vue'),
    meta: {
      navbar:false,
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue'),
    meta: {
      requiresAuth: true, 
      navbar:true,
    }
  },
  {
    path: '/directories',
    name: 'Directories',
    component: () => import('../views/Directories.vue'),
    meta: {
      requiresAuth: false, 
      navbar:true,
    }
  },
  {
    path: '/report/:name',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    meta: {
      requiresAuth: true,
      navbar:true, 
    }
  },
  {
    path: '/directory/:name',
    name: 'Directory',
    component: () => import('../views/Directory.vue'),
    meta: {
      requiresAuth: false, 
      navbar:true,
    }
  },
  {
    path: '/category/:name',
    name: 'ReportCategory',
    component: () => import('../views/ReportCategory.vue'),
    meta: {
      requiresAuth: true, 
      navbar:true,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true, 
      requiresMTB:true,
      navbar:true,
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue'),
    meta: {
      requiresAuth: true, 
      requiresMTB:true,
      navbar:false,
    }
  },
  {
    path: '/resendEmailVerification',
    name: 'resendEmailVerification',
    component: () => import('../views/Account/resendEmailVerification.vue'),
    meta: {
      requiresAuth: true, 
      requiresMTB:true,
      navbar:false,
    }
  },
  

  // REPORTS
  { 
    path: '/2021-scaleups-south-korea',
    name: '2021-scaleups-south-korea',
    component: () => import('../views//Reports/2021-south-korea-tech-scaleup.vue'),
    meta: {
      requiresAuth: true, 
      navbar:false,
    }
  },
  { 
    path: '/2021-scaleups-silicon-valley',
    name: '2021-scaleups-silicon-valley',
    component: () => import('../views//Reports/2021-scaleups-silicon-valley.vue'),
    meta: {
      requiresAuth: true, 
      navbar:false,
    }
  },
  {
    path: '/methodology',
    name: 'Methodology',
    component: () => import('../views/Reports/Methodology.vue'),
    meta: {
      requiresAuth: true,
      navbar:false, 
    }
  },
  {  path: '/:pathMatch(.*)*', component: () => import('../views/PageNotFound'),    }
  // {  path: '/:pathMatch(.*)*', component: () => import('../views/Reports'),    }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router guards
  router.beforeEach(async(to, from, next) => {
    // router.beforeResolve((to, from, next) => {


  if(to.matched.some(rec => rec.meta.requiresAuth)){
    let user = firebase.auth().currentUser

    if (user) {

      //check if route needs MTB or ADMIN
      if ((to.matched.some((rec) => rec.meta.requiresMTB) && !(await firebase.auth().currentUser.getIdTokenResult()).claims.mtb) || (to.matched.some((rec) => rec.meta.requiresAdmin) && !(await firebase.auth().currentUser.getIdTokenResult()).claims.admin)) {
        next({
          name: "Reports"
        });
      }

      if (!user.emailVerified){
        next({
          name: 'VerifyEmail'
        })
      } 
      else if(!store.state.userInfo){
        store.dispatch("setUserInfo")
        .then(()=>{
          if (!store.state.userInfo.hasCompletedSignup){
            next({
              name: 'CompleteSignup'
            })
          } 
          else {
          next()
          }
        })
      } 
      else if (store.state.userInfo && !store.state.userInfo.hasCompletedSignup){
        next({
          name: 'CompleteSignup'
        })
      } 
      else if (store.state.userInfo && store.state.userInfo.hasCompletedSignup){next()}

    } else {
      // No user is signed in. Redirect to login
      next({
        name: 'Signup'
      })
    }
  } else {
    // if route is not guarded by auth, proceed
    next()
  }
})

// // router guards
// router.beforeEach((to, from, next) => {
//   // check to see if route has auth guard
//   if(to.matched.some(rec => rec.meta.requiresAuth) && ! firebase.auth().currentUser){
//     next({
//       name: 'Login'
//     })
//   }
//   //If no need for any specific condition
//   next()
// })


export default router
