import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'

Vue.config.productionTip = false
Vue.config.performance = true

Vue.filter('formatCurrency', function(value) {
  if (value) {
      return '$'+value.toFixed(0).toLocaleString()+'M'
      // return '$'+value.toLocaleString()+'M'
  }
});

import firebase from 'firebase/app'
import 'firebase/auth'
require("firebase/auth");
require("firebase/functions");


let app = null

const firebaseConfig = {
  apiKey: "AIzaSyBo2-F6C4rTvhuR6YeCoKqVoqOg9wZuPzQ",
  authDomain: "mtb-research.firebaseapp.com",
  databaseURL: "https://mtb-research.firebaseio.com",
  projectId: "mtb-research",
  storageBucket: "mtb-research.appspot.com",
  messagingSenderId: "228003090182",
  appId: "1:228003090182:web:ad6da8f2f38fa15fc2d887",
  measurementId: "G-WJ9LM1PGBT"
}; 

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development"){
  firebase
    .app()
    .functions()
    .useEmulator("localhost", 5000);
  }
// wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged(() => {
  // init app if not already created
  if(!app){
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

